import keys from 'config/keys';
import browserStorageService from 'services/shared/browserStorage.service';
import notificationService from 'services/shared/notification.service';
import useAuthenticationStore from 'store/authentication/useAuthenticationStore';
import useSocketSlice from 'store/socket-store/useSocketSlice';
import useSocketResponseStore from 'store/soket-response-store/setSocketResponseState';
import useStylesStore from 'store/styles/useStylesStore';

const { setSocketResponseState } = useSocketResponseStore.getState();
const { setStyleState } = useStylesStore.getState();
const { setState } = useAuthenticationStore.getState();


const slug = browserStorageService.getSession('slug')
const { socket } = useSocketSlice.getState();
export const events = [


    {
        name: 'eventHelper',
        handler: (data) => {
            console.log("eventHelper------->", data);
        },
    },

    {
        name: 'retrieveTask',
        handler: (data) => {
            console.log("retrieveTask----->", data);
            setSocketResponseState({ retrieveTaskResponse: data });
        },
    },
    {
        name: 'themeRetriveHandler',
        handler: (data) => {
            console.log("themeRetriveHandler----->", data?.payload?.homePage);
            setStyleState({ HomePageStyle: data?.payload?.homePage });
        },
    },

    {
        name: 'healthCheck',
        handler: (data) => {
            setSocketResponseState({ healthCheckResponse: data });

        },
    },
    {
        name: 'taskCompleteReqByPatient',
        handler: (data) => {
            setSocketResponseState({ taskCompleteReqByPatientResponse: data });
        },
    },
    {
        name: 'submitDateDetails',
        handler: (data) => {
            setSocketResponseState({ submitDateDetailsResponse: data, });
        },
    },

    {
        name: 'submitSiteOrProviderDetails',
        handler: (data) => {
            setSocketResponseState({ submitSiteOrProviderDetailsResponse: data });
        },
    },
    {
        name: 'sessionExpire',
        handler: (data) => {
            if (socket) { socket.disconnect(); }
            useSocketSlice.setState({ socket: null });
            setState({ auth: null });
            browserStorageService.removeCookie('_token');
            window.open(keys.app.clientUrl + `/${slug}`, '_self')
            // console.log("sessionExpire---------->")
        },
    },
    {
        name: 'sessionExpireAlert',
        handler: (data) => {
            setSocketResponseState({ sessionExpireManager: data, });
            document.documentElement.style.setProperty("--costume_Toter--massage", `flex`);
        },
    }
];
