/** @format */

import { useEffect } from "components/lib/react-npm"
import useSocketSlice from "store/socket-store/useSocketSlice"
import { useSocketEventsListener } from "./useSocketEventsListener"
import { io } from "components/lib/npm"
import keys from "config/keys"
import browserStorageService from "services/shared/browserStorage.service"
import useAuthenticationStore from "store/authentication/useAuthenticationStore"
import { encryptSocketData } from "utils/crypto"
import useSocketResponseStore from "store/soket-response-store/setSocketResponseState"
import { events } from "./event/events"

const ENDPOINT = keys.app.socketUrl
const emitEventEncrypt = (data) => {
	if (keys.app.envType !== "local_value") {
		const payload = encryptSocketData(data)
		return payload
	}
	return data
}

export const socketEmitEvent = (event, payload = {}) =>
	useSocketSlice.getState().socket?.emit(event, emitEventEncrypt({ event, payload }))
export function useSocket(handleNavigate) {
	const { auth } = useAuthenticationStore()
	const { socket, setState } = useSocketSlice()

	const Token = auth

	useSocketEventsListener(events)

	useEffect(() => {
		if (Token && !socket) {
			const socketObj = io(ENDPOINT, {
				transports: ["websocket"],
				auth: {
					_token: browserStorageService.getCookie("_token"),
				},
			})

			socketObj.on("connect", () => {
				setState({ socket: socketObj })
			})

			socketObj.on("disconnect", () => {
				console.log("Socket disconnected------------->")
			})

			socketObj.on("connect_error", (err) => {
				browserStorageService.removeCookie("_token")
				window.open(keys.app.clientUrl, "_self")
			})
		}
	}, [Token])

	return {}
}
